import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../../storage/local.service';


@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private previousUrl: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  public previousUrl$ = this.previousUrl.asObservable();
  constructor(private localStorageService: LocalStorageService) {
    let previousUrl = this.localStorageService.getItem('previousUrl');
    // console.log(previousUrl);
    if (previousUrl) {
      this.previousUrl.next(previousUrl);
    }
    this.getPreviousUrl();
  }
  getPreviousUrl() {
    return this.previousUrl.getValue();
  }
  setPreviousUrl(previousUrl: string) {
    this.localStorageService.setItem('previousUrl', previousUrl);
    this.previousUrl.next(previousUrl);
  }
}
